<template>
    <div class="dashboard-container" v-loading="loading">
      <!-- 添加或编辑类别 -->
      
  
      <!-- -------万恶分割线------- -->
  
      <div class="app-container" style="margin-bottom: 50px">
      
      </div>
  
      <!-- 主体内容 -->
  
      <div class="tab">
        <el-table :header-cell-style="{ background: '#F2F6FC', color: '#606266' }" :data="list" border style="width: 100%"
          ref="list" class="emp_table" v-if="list"  >
  
          <el-table-column
            align="center"
            prop="companyName"
            label="企业名称"
            :show-overflow-tooltip="true"
          />
          <el-table-column align="center" prop="contractNo" label="合同编号" :show-overflow-tooltip="true" />
          <el-table-column align="center" prop="title" label="合同标题" :show-overflow-tooltip="true" />
          <!-- <el-table-column align="center" prop="contractType" label="合同类别" :show-overflow-tooltip="true" />
          <el-table-column align="center" prop="orderNum" label="订单数量" :show-overflow-tooltip="true" />
          <el-table-column align="center" prop="contractPrice" label="订单价格 单位：元" :show-overflow-tooltip="true" /> -->
          <!-- <el-table-column align="center" prop="signPerson" label="签订人姓名" :show-overflow-tooltip="true" />
          <el-table-column align="center" prop="signType" label="	签订人类型" :show-overflow-tooltip="true" /> -->
          <el-table-column align="center" prop="signDate" label="签订时间" :show-overflow-tooltip="true" />
     
          <el-table-column
            align="center"
            prop="tel"
            label="联系电话"
            :show-overflow-tooltip="true"
          />
      
          <el-table-column align="center" prop="status" label="合同状态" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.status==100" style="color:#000000">{{ scope.row.status |htzt }}</span>
              <span v-else-if="scope.row.status==400" style="color:#707070">{{ scope.row.status |htzt }}</span>
              <span v-else-if="scope.row.status==201" style="color:#F5A623">{{ scope.row.status |htzt }}</span>
              <span v-else-if="scope.row.status==300" style="color:#4a86e8">{{ scope.row.status |htzt }}</span>
                    <span v-else-if="scope.row.status==202" style="color: #60B572">{{ scope.row.status |htzt  }}</span>
                    <span v-else-if="scope.row.status==203" style="color: #D0021B">{{ scope.row.status |htzt  }}</span>
                    <span v-else>{{ scope.row.status |htzt  }}</span>
            </template>
          </el-table-column>
          
          <el-table-column align="center" label="操作"  width="300px">
            <template slot-scope="scope">
            
              <el-button type="text" size="small" @click="gotoDetai(scope.row)">
                合同详情
              </el-button>
             
           
          
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
          @pagination="areaPageList" />
      </div>
    </div>
  </template>
    
  <script>
  import {
   
    areaPageList,
 
  } from "../../request/http";
  
  export default {
    data() {
      return {
    
        loading: true,//加载动画
        title: "",
        showDialog: false,
        list: "",
        total: 0,
     
        queryParams: {
          pageNum: 1,
          pageSize: 10,
         
        },
      
        dialogVisible: false, //显示添加或编辑框
       
      
       
      };
    },
  
   created() {
    this.areaPageList()
     
        },
      
    mounted() { 
   
    },
    methods: {
      
      gotoDetai(val){
        
     if(val.status==100||val.status==201||val.status==203){
      //待审核
         
      let contractId = val.contractId;
      console.log(contractId);
        this.$router.push({
          path: "/contractReview",
          query: { contractId },
        });
     }
     else if(val.status==202||val.status==300||val.status==400){
      let contractId = val.contractId;
      let companyId =Number( this.companyId)
      this.$router.push({
          path: "/contractInfo",
          query: { companyId,contractId },
        });
     }
      },
    
     
 
       //获取列表
       async areaPageList() {
          let { data } = await areaPageList(this.queryParams);
        let list = data.data;
        this.list = list.records;
         console.log(this.list);
        this.total = list.total;
        this.loading = false
      },
      
    },
  };
  </script>
    
  <style scoped>
  
  </style>
    